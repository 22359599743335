import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionDivider from "../components/atomic/section-divider"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

export default function OnasPage() {

  const data = useStaticQuery(graphql`{
  webRef: file(relativePath: {eq: "web_services.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
    }
  }
  chivas: file(relativePath: {eq: "chivas.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  pnr: file(relativePath: {eq: "pnr.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  appsRulezz: file(relativePath: {eq: "aps_rulezz.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  aceLogo: file(relativePath: {eq: "ace_logo.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  majzel: file(relativePath: {eq: "majzel.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 30, layout: FULL_WIDTH)
    }
  }
}
`)



  return (
    <Layout>
      <SEO title="O nás" />
      
     
      <div className="subhero">   
      <div className="hero">
        <div className="container">
          <div className="row">
          <div className="col-12">
            <div className="title"><h1>Robíme veci, <span style={{color: "#F94166"}}>na ktorých záleží</span>.</h1></div>
            <p>Sme tím mladých ľudí, ktorí milujú IT a moderné technológie. Veľmi radi Vás privítame v našom svete alebo Vám pomôžeme ak sa v týchto sférach radi nepohybujete.</p>
        
          </div>
          </div>
        </div>
      </div> 
      </div>  
    

      <div className="section-pd  white-bg ">
        <div className="container">
          <SectionDivider name="best approach" white={false}/>
          <div className="row mt40">
            <div className="col-4 section-heading-black">
              <h2>Naše štyri piliere</h2>
              <p>Bez nich sa nepúšťame do žiadnych projektov pretože na nich stojí náš úspech.</p>
            </div>
            <div className="col-4">
              <h3>Dedikovaný tím</h3>
              <div className="regular-text">Ak je to možné, snažíme sa udržať rovnakých dedikovaných pracovníkov pre Vaše projekty. Veríme, že takto môžeme byť viac efektívni a taktiež môžeme posilniť vzťahy medzi ľuďmi, ktorí spolu pracujú.</div>
            </div>
            <div className="col-4">
              <h3>Pravidelný dialóg s klientom</h3>
              <div className="regular-text">
              Pri spolupráci sa snažíme manažmentom prispôsobiť klientovi. To je dôvodom prečo bežne plánujeme stretnutia a telefonáty pravidelne, aby sme pochopili Vaše potreby, prediskutovali nápady a overili správnosť navrhovaných riešení. Vždy sme Vám k dispozícii.
              </div>
            </div>
        </div>
        <div className="row mt40">
          <div className="col-4"></div>
          <div className="col-4">
              <h3>Agilný vývoj</h3>
              <div className="regular-text">
              Vývoj softvéru realizujeme agilnou metódou. Naši projektoví manažéri sú školení  v scrum rámci a starajú sa o plynulý priebeh s cieľom dodržať termíny s požadovanou kvalitou. 
              </div>
            </div>
            <div className="col-4">
              <h3>Príprava</h3>
              <div className="regular-text">
                Našou filozofiou je, že ak čo najlepšie pochopíme svety, v ktorom sa naši klienti pohybujú môžeme tak navrhnúť a vyvíjať softvér oveľa viac podľa očakávaní klientov.
              </div>
            </div>
        </div>

      
        </div>
      </div>

      <div className="section-pd  white-bg ">
        <div className="container">
          <SectionDivider name="now seriously" white={false}/>

          <div className="row mt40">
            <div className="col-8">
              <div className="row section-heading-black"><h2>Prácu berieme vážne, no radi sa zabávame.</h2></div>
              <div className="row section-subtitle-black"><p>„Prijateľné“ nie je pre nás možnosťou. Skutočne sa usilujeme o <strong>to najlepšie!</strong> To je dôvod, prečo sa snažíme najať najlepších ľudí v oblasti technológií, marketingu, analýzy údajov a osobných vzťahov. Nemôžete byť najlepší, ak ste spokojní s priemernosťou. To je dôvod, prečo disponujeme ľuďmi s PhD. alebo rôznymi oceneniami v priemysle. Našou filozofiou je užívať si jazdu a veríme, že sme pre Vašu cestu správnymi spoločníkmi. Presvedčte sa!</p> </div>
              
              <div className="ceo-name">Ing. Matej Majzel<br></br> konateľ spoločnosti</div>
              
            </div>
            <div className="col-4"><GatsbyImage
              image={data.majzel.childImageSharp.gatsbyImageData}
              style={{"height":"100%"}}
              objectFit="cover"
              objectPosition="50% 50%"
              alt="Matej Majzel" />  </div>
          </div>

        </div>
      </div>


      <div className="section-pd section-pd-bottom  white-bg ">
        <div className="container">
          <SectionDivider name="Awards" white={false}/>

          <div className="row mt40">
            <div className="col-4">
              <div className="row section-heading-black"><h2>Boli sme niekoľko krát ocenení za naše produkty a služby</h2></div>
            </div>
            <div className="col-4">
            <div className="section-subtitle-black"><p className="m0">Niekedy treba viac šťastia ako úsilia, no musíme uznať, že v týchto prípadoch to bolo oboje.</p> </div>
            </div>
          </div>

          <div className="row mt40">
            <div className="col-3">
              <div className="awards">
                <div className="logo"><GatsbyImage
                  image={data.chivas.childImageSharp.gatsbyImageData}
                  style={{"height":"100%"}}
                  objectFit="cover"
                  objectPosition="50% 50%" />  </div>
                <h3>Chivas Venture 2018 finalista</h3>
                <div className="regular-text">Chivas Venture každý rok podporuje sociálnych podnikateľov, ktorí kombinujú zisk s cieľom mať pozitívny vplyv na svet.</div>
              </div>
            </div>
            <div className="col-3">
              <div className="awards">
                <div className="logo"><GatsbyImage
                  image={data.pnr.childImageSharp.gatsbyImageData}
                  style={{"height":"100%"}}
                  objectFit="cover"
                  objectPosition="50% 50%" />  </div>
                <h3>Podnikateľský nápad roka 2014</h3>
                <div className="regular-text">S našou aplikáciou sme sa dostali do finále a zaradili sa medzi TOP 3 mobilné aplikácie.</div>
              </div>
            </div>
            <div className="col-3">
              <div className="awards">
                <div className="logo"><GatsbyImage
                  image={data.appsRulezz.childImageSharp.gatsbyImageData}
                  style={{"height":"100%"}}
                  objectFit="cover"
                  objectPosition="50% 50%" />  </div>
                <h3>Apps RULEZZ 2016</h3>
                <div className="regular-text">V kategórii Neznačkové aplikácie sme sa umiestnili na 2. mieste v kategórii Aplikácie pre tablety a 3. v kategórii Najlepší vývojár aplikácií roka.</div>
              </div>
            </div>
            <div className="col-3">
              <div className="awards">
                <div className="logo"><GatsbyImage
                  image={data.aceLogo.childImageSharp.gatsbyImageData}
                  style={{"height":"100%"}}
                  objectFit="cover"
                  objectPosition="50% 50%" />  </div>
                <h3>Creative Business Cup 2016</h3>
                <div className="regular-text">Zaradili sme sa medzi TOP 3 najlepšie projekty v ACE Creative Bootcamp (Slovenské národné kolo), ktoré je súčasťou národnej súťaže Creative Business Cup.</div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </Layout>
  );

}
